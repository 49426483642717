
:root {

/* TagManager- modal whattsapp */
.modalDD.right.fadeDD .modalDD-dialog {
    bottom: 25px !important;
z-index: 9999999!important;
}
/* TagManage- deixa icone por baixo de modal */
.anima{
z-index: 999!important;
}




    body {
        margin: 0;
        font-family: Orbitron;
        background-color: var(--light-color);
        color: var(--dark-color);
    }

    --primary-color: #2B9BC7;
    --secondary-color: #79C9D9;
    --bottom-primary-collor-white: White;
    --menu-blue-color: #102F51;

    /* Remove a borda de foco quando o botão é ativado 

    --font-size-small: 0.875rem;
    --font-size-medium: 1rem;
    --font-size-large: 1.25rem;

   
    --spacing-small: 8px;
    --spacing-medium: 16px;
    --spacing-large: 24px;

    --border-radius-small: 4px;
    --border-radius-medium: 8px;
    --border-radius-large: 12px;

  

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: var(--primary-color);
    }

 

    button:hover {
        background-color: var(--dark-color);
    }

    .container {
        padding: var(--spacing-large);
    }
Remove a borda de foco quando o botão é ativado */
}

.main-frame::before{
    display: block;
    content: '';
    width: 100%;
    height: 50px;
 }